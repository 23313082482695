li {
  border-radius: 5px;
}
li a {
  text-decoration: none !important;
  font-size: 0.8rem;
  color: black !important;
  padding: 0.5rem;
  /* width: 100%; */
  text-align: justify;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

li:hover a {
  /* border: 1px solid red; */
  color: #fff !important;
}

.active {
  background-color: #d64d3a;
  border-bottom: 4px solid #40a4c8;
  color: white;
}
