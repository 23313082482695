#sidebar {
  overflow: hidden;
  z-index: 3;
}
#sidebar .list-group {
  width: 250px;
  background-color: #03589b;
  min-height: 100vh;
}
#sidebar i {
  margin-right: 6px;
}

#sidebar .list-group-item {
  border-radius: 0;
  background-color: #03589b;
  color: #fff;
  border-left: 0;
  border-right: 0;
  border-color: #023e6c;
  white-space: nowrap;
}

#sidebar .list-group-item:hover {
  background-color: #0576cc;
}

/* highlight active menu */
#sidebar .list-group-item:not(.collapsed) {
  background-color: #222;
}

/* closed state */
#sidebar .list-group .list-group-item[aria-expanded="false"]::after {
  content: " \f0d7";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 5px;
}

/* open state */
#sidebar .list-group .list-group-item[aria-expanded="true"] {
  background-color: #222;
}
#sidebar .list-group .list-group-item[aria-expanded="true"]::after {
  content: " \f0da";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 5px;
}

/* level 1*/
#sidebar .list-group .collapse .list-group-item,
#sidebar .list-group .collapsing .list-group-item {
  padding-left: 20px;
}

/* level 2*/
#sidebar .list-group .collapse > .collapse .list-group-item,
#sidebar .list-group .collapse > .collapsing .list-group-item {
  padding-left: 30px;
}

/* level 3*/
#sidebar .list-group .collapse > .collapse > .collapse .list-group-item {
  padding-left: 40px;
}

@media (max-width: 991px) {
  #sidebar {
    width: 55px;
    max-width: 60px;
    overflow-y: auto;
    overflow-x: visible;
    transition: all 0.25s ease;
    transform: translateX(-45px);
    position: relative;
  }

  #sidebar.show {
    transform: translateX(0);
  }

  #sidebar::-webkit-scrollbar {
    width: 0px;
  }

  #sidebar,
  #sidebar .list-group {
    width: 65px;
    overflow: visible;
  }
  /* overlay sub levels on small screens */
  #sidebar .list-group .collapse.show,
  #sidebar .list-group .collapsing {
    position: relative;
    z-index: 1;
    width: 190px;
    top: 0;
  }
  #sidebar .list-group > .list-group-item {
    text-align: center;
    padding: 0.75rem 0.5rem;
  }
  /* hide caret icons of top level when collapsed */
  #sidebar .list-group > .list-group-item[aria-expanded="true"]::after,
  #sidebar .list-group > .list-group-item[aria-expanded="false"]::after {
    display: none;
  }
}

.collapse.show {
  visibility: visible;
}
.collapsing {
  visibility: visible;
  height: 0;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.collapsing.width {
  -webkit-transition-property: width, visibility;
  transition-property: width, visibility;
  width: 0;
  height: 100%;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
